import React from "react";
import { NavLink } from "react-router-dom";
import raw from 'raw.macro';
import ReactMarkdown from 'react-markdown';

const markdownContent = raw('../mds/about.md');

const About = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">ABOUT</div>
				<div id="abt">
					<ReactMarkdown className="md">
					{markdownContent}
					</ReactMarkdown>
				</div>
			</div>
			</div>
			

			
		
		</div>
	);
};

export default About;

import React, { useState } from "react";

const ApiCallComponent = ({ onApiResponse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Get selected file
  };

  const handleButtonClick = async () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }
  
    setIsLoading(true);
    const reader = new FileReader();
  
    reader.onloadend = async () => {
      const base64File = reader.result.split(',')[1]; // Remove the "data:" part
      const formData = {
        fileData: base64File,
        fileName: file.name,
      };
  
      try {
        const response = await fetch("https://api.timothy-mah.com/upload", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          const data = await response.json(); // Convert response to JSON
  
          setMessage(data.body.message);
          setDownloadUrl(data.body.download_url || 'No URL provided');
          onApiResponse(data); // Send response back to parent
        } else {
          // Convert response to JSON to access the error message
          const errorData = await response.json();
          setMessage(`Error: ${errorData.error || 'API request failed'}`);
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };
  
    reader.readAsDataURL(file); // Read the file as base64
  };
  

  return (
    <div className="upload-box">
      <div className="choose-file-box">
        <input className="choose-file-btn" type="file" accept=".csv" onChange={handleFileChange} />
        <button className="upl-file-btn" onClick={handleButtonClick} disabled={isLoading}>{isLoading ? "Uploading..." : "Upload CSV"}</button>
      </div>
      {message && <p>{message}</p>}
      {downloadUrl && (
        <p className="downl_btn">
          <a  href={downloadUrl} target="_blank" rel="noopener noreferrer">Download File</a>
        </p>
      )}
    </div>
  );
};

export default ApiCallComponent;

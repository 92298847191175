import React from "react";

// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="btmBar">
      <div id="creds">
        <p>
          This is my portfolio website. Hopefully it creates more job prospects for me. Please don't sue me
          <br />
          <br />
          Timothy Mah's portfolio website © 2025 by Timothy Mah is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"> CC BY-NC-SA 4.0</a> 
        </p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import ApiCallComponent from "../helpers/api-call.jsx";
import ReactMarkdown from 'react-markdown';
import raw from 'raw.macro';

const markdownContent = raw('../mds/tenter.md');

const ProjTentmaker = () => {
  const [apiResponse, setApiResponse] = useState(null);

  const handleApiResponse = (response) => {
    setApiResponse(response); // Update the state with the API response
  };

  return (
    <div id="b1" style={{ backgroundColor: "#efeed8" }}>
      <div className="header-spacing"></div>
      <div className="aboutpage-con">
        <div className="about-con">
          <div className="title">Tent card maker</div>
          <div id="abt">
            <a href="/projs">👈 back to projects</a>
            <div className="title2">Tent Card Maker</div>
            <img
              className="proj-pic"
              src={process.env.PUBLIC_URL + "/images/tent-card.jpg"}
              alt=""
            />
            <br />
            <ReactMarkdown className="md">
            {markdownContent}
            </ReactMarkdown>

            {/* Call the ApiCallComponent */}
            <ApiCallComponent onApiResponse={handleApiResponse} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjTentmaker;

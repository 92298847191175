import React from "react";
import raw from 'raw.macro';
import ReactMarkdown from 'react-markdown';

const markdownContent = raw('../mds/thisWebsite.md');

const ThisWebsite = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			<div className="about-con">
                
				<div className="title">This Website</div>
                
                <div id="abt">
                    <a href="/projs">👈 back to projects</a>
                    <div className="title2">This Website</div>
                    
                    <img
                        className="proj-pic"
                        src={process.env.PUBLIC_URL + "/images/yaml.jpg"}
                        alt=""
                    />
					<br></br>
					<ReactMarkdown className="md">
					{markdownContent}
					</ReactMarkdown>
                </div>
			</div>
			
            </div>
			
		
		</div>
	);
};

export default ThisWebsite;
